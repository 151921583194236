body {
    font-family: 'Courier New', Courier, monospace; /* Use Courier New for typewriter feel */
    background-color: #fafafa; /* Light gray background resembling paper */
    color: #333; /* Dark gray text color */
    text-align: justify; /* Justify text */
    margin: 0;
    padding: 0;
  }
  
  .typing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px; /* Add padding for better layout */
  }
  
  #typing-text {
    font-size: 22px; /* Increased font size */
    line-height: 1.6; /* Adjusted line height for readability */
    white-space: pre-wrap;
    margin-bottom: 20px;
    text-align: justify; /* Justify text */
    max-width: 800px; /* Limit the width of the typing text */
    overflow-wrap: break-word; /* Ensure long words wrap to the next line */
  }
  
  .email-text {
    display: flex;
    justify-content: center;
  }
  
  .reach-me-button {
    padding: 10px 20px;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reach-me-button:hover {
    background-color: #0056b3;
  }
  
  .cursor {
    display: inline-block;
    animation: blink-caret 0.75s step-end infinite;
    color: #333; /* Dark gray color for cursor */
  }
  
  @keyframes blink-caret {
    50% {
      opacity: 0;
    }
  }
  
  /* Media Query for smaller screens (max-width: 768px) */
  @media (max-width: 768px) {
    #typing-text {
      font-size: 18px; /* Adjusted font size for smaller screens */
      line-height: 1.4; /* Adjusted line height for smaller screens */
      max-width: 90%; /* Limit width to 90% of the viewport */
    }
  
    .reach-me-button {
      font-size: 16px; /* Smaller font size for button on smaller screens */
      padding: 8px 16px; /* Adjusted padding for button on smaller screens */
    }
  }
  